module.exports = {
  title_welcome: "欢迎光临, ",
  information:
    "与AYANA Rewards会员一起体验如您般奢华的奖励。每次入住均可享受免费住宿、独家升级、仅限会员的优惠及特别欢迎礼品。",
  member_name_label: "会员姓名",
  learn_more: "了解更多",
  expires_on: "截止日期:",
  earn_points: "赚取积分",
  claim_rewards: "報酬を請求する",
  rewards_points: "奖励积分",
  nights: "晚",
  level_points: "等级积分",
  upgrade_level: "了解有关升级您的会员等级的信息",
  maintain_level: "了解如何维持您的等级",
  maintain_memebrship: "维持您的会员等级",
  upgrade_membership: "升级会员等级",
  membership_no: "会员编号",
  label_membership: "会员资格 ",
  membership_gold_label: "Gold",
  membership_silver_label: "Silver",
  membership_platinum_label: "Platinum",
  point_label: "会员点数",
  menu_tab_profile: "个人信息",
  menu_tab_my_redeem: "点数兑换",
  menu_tab_password_change: "修改密码",
  menu_tab_missing_point: "无效点数",
  menu_tab_reward_history: "兑换历史",
  menu_tab_future_booking: "未来的预订",
  menu_tab_feedback: "反馈",
  menu_tab_my_voucher: "我的优惠券",
  menu_tab_transaction_history: "积分记录",
  menu_tab_my_reservation: "我的预订",
  label_your_point: "您的积分",
  label_expired_on: "有效期限",
  label_required_nex_point: "还需 %pts% 积分",
  claim_rewards_popup_drawer_title: "申请奖励",
  claim_rewards_popup_drawer_description:
    "使用积分支付酒店费或参与品牌的独享体验。",
  claim_rewards_popup_drawer_explore: "探索以下的奖励兑换",
  claim_rewards_popup_drawer_pay_with_points: "用积分支付",
  claim_rewards_popup_drawer_pay_with_points_description:
    "选择使用多少积分用于酒店预订，提供交易灵活性。",
  claim_rewards_popup_drawer_voucher: "奖励券",
  claim_rewards_popup_drawer_voucher_description:
    "用积分兑换多种多样的优惠券，包括酒店、用餐、水疗等。",
  rewards_points_title: "关于AYANA Rewards积分",
  rewards_points_terms_conditions: "阅读AYANA Rewards的条款与条件",
  rewards_points_description:
    "通过我们参与品牌的合格房价、餐饮、SPA护理和娱乐设施赚取积分。",
  rewards_points_expiration_detail:
    "积分从您最后一次活动（登录、赚取积分和兑换积分）之日起有效期为两年。",
  your_benefits: "你的好处",
  exclusive_room_discount: "专属房间折扣",
  exclusive_discount_participating_brands: "参与品牌的独家折扣",
  birthday_gift_during_stay: "住宿期间的生日礼物*",
  wellness_benefits: "健康福利*",
  tier_room_upgrade: "一级房升级",
  early_check_in: "上午11点起的提前入住*",
  late_check_out_4pm: "下午4点之前的延迟退房*",
  bonus_points_25: "25%的奖励积分",
  welcome_gifts: "欢迎礼物**",
  complimentary_one_time_breakfast: "一次性免费早餐",
  bonus_points_50: "50%的奖励积分",
  late_check_out_6pm: "下午6点之前的延迟退房*",
  based_on_availability: "基于可用性",
  varies_by_property: "因物业而异",
  learn_more_about_member_benefit: "了解更多关于会员福利",
  maintaining_your_level: "维持您的等级",
  earn_more_level_points: "赚取更多的等级积分",
  terms_conditions: "条款与条件",
  or: "或者",
};
