module.exports = {
  title_redemption_date: "兑换日期",
  title_voucher_code: "优惠券编号",
  title_category: "类别",
  title_voucher_name: "优惠券名称",
  title_point_redeemed: "已兑换积分",
  title_voucher_expiry: "优惠券到期日",
  title_status: "状态",
  button_title: "申请奖励",
  description: "使用您的积分兑换代金券优惠。",
};
